import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const state = {
    list: [],
    inventory: [],
    statuses: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules,
    steps: [
        {
          id: 2,
          step: '2',
          name: i18n.t('message.n_step', {n: 2}),
        },
        {
          id: 3,
          step: '3',
          name: i18n.t('message.n_step', {n: 3}),
        },
        {
          id: 4, 
          step: '3-2',
          name: i18n.t('message.n_step', {n: '3'}) + ' земля',
        }
    ]
};
