<template>
    <aside class="menu" :class="[ leftVersion ? (mode ? 'theme-Dark-style-left' : 'theme-Light-style-left') : '']">
        <div class="menu-wrapper" :class="{ dark: mode, 'menu-wrapper_active': drawer }">
            <!-- menu -->
            <ul class="menu-wrapper-list">
                <!-- items -->
                <li @mouseenter="drawer = false" class="c-f-hover">
                    <router-link class="menu-wrapper-list__item" to="/">
                        <i class="fa-solid fa-chart-simple"></i>
                        {{ $t('message.home') }}
                    </router-link>
                </li>
                <button
                    @click="item.uniqueKey && item.uniqueKey == 'category-layout' && $route.name !== 'settingsCategory' ? $router.push({ name: 'settingsCategory' }) : false"
                    @mouseenter="action(item)" v-for="(item, index) in findPath" :key="index" v-can-or="item.is_show"
                    class="menu-wrapper-list__item"
                    :class="{ active: item.items && item.items.length ? item.items.some(el => el.active) : $route.name == 'settings.index' }">
                    <i :class="item.icon"></i>
                    {{ $t(item.name) | truncate(8, '...') }}
                </button>
            </ul>
        </div>

        <!-- sub menu -->
        <div class="menu-drawer" :class="{ active: drawer }">
            <ul @mouseenter="drawer = true" @mouseleave="drawer = false" class="menu-drawer-submenu"
                :class="{ dark: mode }" v-if="(subMenu && subMenu.length)">
                <div class="menu-drawer-submenu__header">
                    <h3 class="menu-drawer-submenu__title">{{ $t(subMenuTitle)  }}</h3>
                    <i @click="drawer = false" class="el-icon-close"></i>
                </div>

                <li v-if="route.can_show" @click="drawer = false" v-for="(route, subIndex) in subMenu" :key="subIndex">
                    <router-link class="menu-drawer-submenu__item" :to="{ name: route.link }">
                        <!-- <i :class="[route.icon]"></i> -->
                        {{ $t(route.name) }}
                    </router-link>
                </li>
            </ul>
            <div @mouseover="drawer = false" class="menu-drawer__bg"></div>
        </div>


    </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data: () => ({
        drawer: false,
        direction: 'ltr',
        subMenu: [],
        subMenuTitle: '',
        activeNames: [],
        activeRouteIndex: null,
        activeRouteSubIndex: null,
        list: [
            {
                link: '#',
                name: 'message.deals',
                tooltip: 'Order',
                icon: 'fa-solid fa-handshake-simple',
                open: false,
                listOne: false,
                is_show:[
                    'deals.index',
                ],
                items: [
                    {
                        name: 'message.deals',
                        link: 'deals',
                        is_show: 'deals.index'
                    },
                ]
            },
            {
                link: false,
                name: 'message.cash_register',
                tooltip: 'User',
                icon: 'fa-solid fa-sack-dollar',
                open: false,
                listOne: false,
                is_show:[
                    'dealPayments.index',
                    'paymentTypes.index',
                    'currencies.index',
                ],
                items: [
                    {
                        name: 'message.dealPayments',
                        link: 'dealPayment',
                        is_show: 'dealPayments.index'
                    },
                    {
                        name: 'message.paymentTypes',
                        link: 'paymentTypes',
                        is_show: 'paymentTypes.index'
                    },
                    {
                        name: 'message.currencies',
                        link: 'currency',
                        is_show: 'currencies.index'
                    },
                ]
            },
            {
                link: '#',
                name: 'message.clients',
                tooltip: 'Order',
                icon: 'fa-solid fa-users',
                open: false,
                listOne: false,
                is_show:[
                    'clients.index',
                ],
                items: [
                    {
                        name: 'message.clients',
                        link: 'clients',
                        is_show: 'clients.index'
                    },
                ]
            },
            {
                link: '#',
                name: 'message.h_r',
                tooltip: 'Order',
                icon: 'fa-solid fa-user-gear',
                open: false,
                listOne: false,
                is_show:[
                    'users.index',
                    'roles.index',
                    'permissions.index',
                ],
                items: [
                    {
                        name: 'message.users',
                        link: 'users',
                        is_show: 'users.index'
                    },
                    {
                        name: 'message.roles',
                        link: 'roles',
                        is_show: 'roles.index'
                    },
                    {
                        name: 'message.permissions',
                        link: 'permissions',
                        is_show: 'permissions.index'
                    },
                ]
            },
            {
                link: '#',
                name: 'message.settings',
                tooltip: 'Saved',
                icon: 'fa-solid fa-gear',
                open: false,
                listOne: false,
                is_show:[
                    'categories.index',
                    'attributes.index',
                    'documentTemplates.index',
                    'cities.index',
                    'regions.index'
                ],
                items: [
                    {
                        name: 'message.categories',
                        link: 'category',
                        is_show: 'categories.index'
                    },
                    {
                        name: 'message.attributes',
                        link: 'attributes',
                        is_show: 'attributes.index'
                    },
                    {
                        name: 'message.documentTemplates',
                        link: 'documentTemplates',
                        is_show: 'documentTemplates.index'
                    },
                    {
                        name: 'message.cities',
                        link: 'city',
                        is_show: 'cities.index'
                    },
                    {
                        name: 'message.regions',
                        link: 'region',
                        is_show: 'regions.index'
                    },
                ]
            },
        ],
        fixed: false
    }),
    created() {
        this.list.forEach(m_route => {
            if(m_route.items && m_route.items.length > 0){
                m_route.items.forEach(item => {
                    item.can_show = this.checkPermission(item.is_show);
                });
            }
        })
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions"
        }),
        // aside is expanded
        isExpanded() {
            return this.$store.getters.MENU
        },
        // dark mode
        mode() {
            return !this.$store.getters.MODE
        },
        leftVersion () {
        return this.$store.getters.LEFTVERSION
        },
        findPath() {
            this.list.map(item => {
                if (item.items && item.items.length) {
                    return item.items.map(route => {
                        Object.assign(route, { active: route.link == this.$route.name });
                    })
                }
            })
            return this.list
        },
    },
    watch: {
        isExpanded() {
            if (!this.isExpanded) {
                this.activeNames = []
            }
        },
    },
    methods: {
        checkPermission(perm) {
            return this.permissions.find(el => el.slug == perm) ? true : false;
        },
        accordion() {
            if (!this.isExpanded) {
                this.activeNames = []
            }
        },
        openToggle() {
            this.$store.commit('toggleMenu')
        },
        action(item) {
            if (!item.uniqueKey) {
                this.drawer = true
                this.subMenuTitle = item.name
                this.subMenu = item.items
            } else {
                this.drawer = false
            }
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix
            } else {
                return text
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.menu {
    position: relative;
    z-index: 1001;
    width: 70px;

    // menu-wrapper
    &-wrapper {
        width: inherit;
        height: 100%;
        min-height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        // border-right: 1px solid #E6E9F2;
        padding: 58.5px 0px 0;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        direction: rtl;
        z-index: 999;
        background-color: #fff;

        // menu-wrapper-list
        &-list {
            display: flex;
            flex-direction: column;
            direction: ltr;
            gap: 6px;
            padding: 0;
            margin: 0;
            list-style: none;

            // menu-wrapper-list__item
            &__item {
                font-size: 11px;
                font-weight: 500;
                color: #92929f;
                background-color: transparent;
                margin: 0;
                padding: 10px;
                border: none;
                outline: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                transition: all .2s ease-in-out;
                cursor: pointer;
                margin: 0 5px;

                &.router-link-exact-active {
                    color: #fff;
                    background-image: linear-gradient(to left bottom, #0d67c9, #0080d0, #0090ab, #00986a, #39991a) !important;
    border-radius: 5px;
                }

                &.active {
                    color: #fff;
                    background-image: linear-gradient(to left bottom, #0d67c9, #0080d0, #0090ab, #00986a, #39991a) !important;
    border-radius: 5px;
                }

                &:hover {
                    color: #0c67c9;
                    background: #e3f1ff;
                    border-radius: 5px;
                }

                i {
                    font-size: 20px;
                }
            }
        }

        // scrool bar style
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #ebeef5;
            border: 2px solid #fff0;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #8a909c;
            border-color: #8a909c;
        }

        // dark mode
        &.dark {
            background-color: #080c24;
            border: none;

           .menu-wrapper-list__item i {
                margin-bottom: 5px;
                &:hover {
                    color: #0d67c9;
                    border-radius: 5px;
                    // background-color: #323248;
                }

                &.router-link-exact-active {
                    color: #fff;
                    // background: #0d67c9;
                    background-image: linear-gradient(to left bottom, #0d67c9, #0080d0, #0090ab, #00986a, #39991a) !important;
    border-radius: 5px;
                }
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #1f2937;
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: #374151;
                border-color: #374151;
            }
        }
    }
}

//  SUBMENU
.menu-drawer {
    position: fixed;
    padding-top: 57px;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: 998;
    transition: all .35s ease-in-out;

    &.active {
        left: 70px;
    }

    // menu-drawer-submenu
    &-submenu {
        width: 18%;
        height: 100%;
        padding: 1rem;
        margin: 0;
        list-style: none;
        position: relative;
        background-color: #fff;
        z-index: 998;
        overflow-x: hidden;
        overflow-y: auto;
        // box-shadow: 1px 0px 4px rgb(0 0 0 / 20%);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #ebeef5;
            border: 2px solid #fff0;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: #d1d7e4;
            border-color: #d1d5db;
        }

        // menu-drawer-submenu__header
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 4px 0;

            i {
                font-size: 25px;
                cursor: pointer;
            }
        }

        // menu-drawer-submenu__item
        &__item {
            color: #55555A;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 10px 6px;
            border-radius: 4px;
            text-decoration: none;
            transition: all .2s ease-in-out;
            cursor: pointer;

            &.router-link-exact-active {
                color: #0d67c9;
                background-image: linear-gradient(to left, #dcedff, #d5f4ff, #d4f9f9, #dcfdf0, #ecffe6);

                i {
                    color: #fff;
                }
            }

            i {
                color: #91A2B6;
            }

            &:hover {
                color: #0d67c9;
                background-image: linear-gradient(to left, #dcedff, #d5f4ff, #d4f9f9, #dcfdf0, #ecffe6);

                i {
                    color: #0d67c9;
                }
            }
        }

        /* ----------------------- DARK MODE -----------------------*/
        &.dark {
            background: #080c24a6;
            backdrop-filter: blur(10px);

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #1f2937;
            }

            &:hover::-webkit-scrollbar-thumb {
                background-color: #374151;
                border-color: #374151;
            }

            .menu-drawer-submenu {

                &__title {
                    color: #fff;
                }

                &__header i {
                    color: #fff !important;
                }

                // menu-drawer-submenu__item
                &__item {
                    color: #fff;
                    opacity: .5;

                    i {
                        opacity: .5;
                        color: #fff;
                    }

                    &.router-link-exact-active,
                    &:hover {
                        opacity: 1;
                        // background-color: #2a2a3f;
                        color: #0d67c9;
                        background-color: #0e2c4685;

                        i {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background: rgba($color: #000000, $alpha: .5);
        background-color: transparent;
        z-index: 997;
        transition: all .35s ease-in-out;
    }
}

button.menu-wrapper-list__item.active i,
button.menu-wrapper-list__item.active,
.menu-wrapper-list__item.router-link-exact-active:hover i{
    color: #fff !important;
}
</style>
