export const model = {
  id : null,  
  deal_id: null,
  price: 0,
  payment_type_id: null,
  currency_rate: 1, 
  currency_id: null, 
  paid: false,
  comment: '',
  payment_date: '',
  created_at: '',
  updated_at: ''  
};
  