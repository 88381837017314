import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.full_name'),
        sortable: true,
        column: 'name'
    },

    date_of_birth : {
        show: true,
        title: i18n.t('message.date_of_birth'),
        sortable: true,
        column: 'date_of_birth'
    },

    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },

    email : {
        show: true,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },

    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
