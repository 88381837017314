import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";
import fourthStepAvtoDefaults from "./properties/fourthStepAvtoDefaults";
import fourthStepLandDefaults from "./properties/fourthStepLandDefaults";
import firstStepLandAddParams from "./properties/firstStepLandAddParams";
import firstStepAvtoAddParams from "./properties/firstStepAvtoAddParams";
import fifthStepTotal from "./properties/fifthStepTotal";
import { thirdStepRules } from "./properties/thirdStepRules";

export const state = {
    list: [],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules,
    fourthStepAvtoDefaults: JSON.parse(JSON.stringify(fourthStepAvtoDefaults)),
    fourthStepLandDefaults: JSON.parse(JSON.stringify(fourthStepLandDefaults)),
    firstStepLandAddParams: JSON.parse(JSON.stringify(firstStepLandAddParams)),
    firstStepAvtoAddParams: JSON.parse(JSON.stringify(firstStepAvtoAddParams)),
    thirdStepRules: thirdStepRules,
    fifthStepTotal: JSON.parse(JSON.stringify(fifthStepTotal)),
};
