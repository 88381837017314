export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort,
    fourthStepAvtoDefaults: state => state.fourthStepAvtoDefaults,
    fourthStepLandDefaults: state => state.fourthStepLandDefaults,
    firstStepLandAddParams: state => state.firstStepLandAddParams,
    firstStepAvtoAddParams: state => state.firstStepAvtoAddParams,
    thirdStepRules: state => state.thirdStepRules,
    fifthStepTotal: state => state.fifthStepTotal,
  };
  