import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    deal_id : {
        show: true,
        title: i18n.t('message.deal'),
        sortable: true,
        column: 'deal_id'
    },
    payment_type_id : {
        show: true,
        title: i18n.t('message.paymentType'),
        sortable: true,
        column: 'payment_type_id'
    },
    price : {
        show: true,
        title: i18n.t('message.price'),
        sortable: true,
        column: 'price'
    },
    currency_rate : {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'currency_rate'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency_id'
    },
    paid : {
        show: true,
        title: i18n.t('message.paid'),
        sortable: true,
        column: 'paid'
    }, 
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    payment_date : {
        show: true,
        title: i18n.t('message.payment_date'),
        sortable: true,
        column: 'payment_date'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
