export const model = {
    id: null,
    object_name: '',
    client_id: null,
    category_id: null,
    city_id: null,
    region_id: null,
    full_address: '',
    currency_id: null,
    currency_rate: 1,
    price: 0,
    client_price: 0,
    quantity: 1,
    cadastre: '',
    state_warrant: '',
    total_usable_area: '',
    number_of_floors: '',
    physical_deterioration: 0,
    payments: [],
    paymentTotal: 0,

    other_inputs: [],
    physicalDeteriorationParamsTable: [],
    attributeComporisonTable: [],
    comporisonTable: [],
    fourthStepTables: [],
    fifthStepTable: [],
    completed: false,
    created_at: '',
    updated_at: ''
  };
  