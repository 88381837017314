export const firstStepLandAddParams = [
    {
        type: 'text',
        calculate: false,
        key: 'foundation',
        name: 'Фундамент',
        value: 'Железобетонный'
    },
    {
        type: 'text',
        calculate: false,
        key: 'walls_and_partitions',
        name: 'Стены и перегородки',
        value: 'Кирпичный'
    },
    {
        type: 'text',
        calculate: false,
        key: 'floors',
        name: 'Перекрытия',
        value: 'Ж/бетонные плит.'
    },
    {
        type: 'text',
        calculate: false,
        key: 'roof',
        name: 'Кровля',
        value: 'Металлическая'
    },
    {
        type: 'text',
        calculate: false,
        key: 'windows_and_doors',
        name: 'Проемы (окна и двери)',
        value: 'Из Акфы '
    },
    {
        type: 'text',
        calculate: false,
        key: 'finishing_work',
        name: 'Отделочные работы',
        value: 'Улучшенный'
    },
    {
        type: 'text',
        calculate: false,
        key: 'electrical_installations',
        name: 'Внутренние санитарно технические и электрические устройства',
        value: '220 кВт'
    },
    {
        type: 'text',
        calculate: false,
        key: '',
        name: '',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'year_of_construction',
        name: 'Год постройки',
        value: 'Не известно'
    },
    {
        type: 'number',
        calculate: false,
        key: 'area',
        name: 'Площадь, м²',
        value: 41.52
    },
    {
        type: 'number',
        calculate: false,
        key: 'height',
        name: 'Высота, м',
        value: 2.8
    },
    {
        type: 'number',
        calculate: true,
        formula: 'area * height',
        key: 'volume',
        name: 'Объем, м³',
        value: 0 // 116.256
    },
    {
        type: 'number',
        calculate: false,
        key: 'price_for_per_area',
        name: 'Стоимость 1м² общей площади здания',
        value: 239
    },
    {
        type: 'number',
        calculate: false,
        key: 'seismicity_coefficient',
        name: 'Коэффициент сейсмичности',
        value: 1.15
    },
    {
        type: 'number',
        calculate: false,
        key: 'height_coefficient',
        name: 'Коэффициент высоты',
        value: 1
    },
    {
        type: 'number',
        calculate: false,
        key: 'territoriality_coefficient',
        name: 'Коэффициент территориальности',
        value: 1
    },
    {
        type: 'number',
        calculate: true,
        formula: 'area * price_for_per_area * seismicity_coefficient * height_coefficient * territoriality_coefficient',
        key: 'replacement_cost',
        name: 'Восстановительная стоимость',
        value: 0 // 11411.77
    },
    {
        type: 'number',
        calculate: false,
        key: 'organization_index',
        name: 'Индекс Госкомстата',
        value: 23.026
    },
    {
        type: 'number',
        calculate: false,
        key: 'entrepreneur_profit',
        name: 'Прибыль предпринимателя',
        value: 1.293
    },
    {
        type: 'number',
        calculate: false,
        key: 'adjustment_for_finishing',
        name: 'Корректировка на отделку',
        value: 1
    },
    {
        type: 'number',
        calculate: false,
        key: 'price_increase_index',
        name: 'Индекс удорожания',
        value: 411.4
    },
    {
        type: 'number',
        calculate: true,
        formula: 'replacement_cost * organization_index * entrepreneur_profit * adjustment_for_finishing * price_increase_index',
        key: 'valuation_date_replacement_cost',
        name: 'Восстановительная стоимость квартиры на дату оценки, сум',
        value: 0 // 139776576.33
    },
];

export default firstStepLandAddParams;
