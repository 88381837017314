import {i18n} from '@/utils/i18n';

export const rules = {
    object_name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.object_name')}), trigger: 'change' },
    ],
    client_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.client')}), trigger: 'change' },
    ],
    category_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.category')}), trigger: 'change' },
    ],
    currency_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency')}), trigger: 'change' },
    ],
    client_price: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.price')}), trigger: 'change' },
    ],
    currency_rate: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.rate')}), trigger: 'change' },
    ],
};
