import {i18n} from '@/utils/i18n';

export const rules = {
    deal_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.deal_id')}), trigger: 'change' },
    ],
    payment_type_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.paymentType')}), trigger: 'change' },
    ],
    price: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.price')}), trigger: 'change' },
    ],
    currency_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency')}), trigger: 'change' },
    ],
    currency_rate: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.rate')}), trigger: 'change' },
    ],
};
