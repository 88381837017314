export const firstStepAvtoAddParams = [
    {
        type: 'text',
        calculate: false,
        key: 'brand',
        name: 'Марка модель авто',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'country',
        name: 'Страна происхождения',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'factory',
        name: 'Завод изготовитель',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'register_number',
        name: 'Государственный регистрационный номер',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'production_year',
        name: 'Год выпуска',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'engin_number',
        name: 'Двигатель №',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'car_body',
        name: 'Кузов №',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'chassis',
        name: 'Шасси №',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'color',
        name: 'Цвет',
        value: ''
    },
    {
        type: 'text',
        calculate: false,
        key: 'technical_condition',
        name: 'Техническое состояние',
        value: ''
    }
];

export default firstStepAvtoAddParams;
