import {i18n} from '@/utils/i18n';

export const thirdStepRules = {
    object_name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.object_name')}), trigger: 'change' },
    ],
    quantity: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.quantity')}), trigger: 'change' },
    ],
    price: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.price')}), trigger: 'change' },
    ]
};
