export const documentTypes = [
    {
        key: 'docx',
        name: 'WORD документ'
    },
    {
        key: 'xlsx',
        name: 'EXCEL документ'
    }
];
