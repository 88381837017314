export const filter = {
  id : null,  
  deal_id: null,
  payment_type_id: null,
  currency_id: null,
  paid: null, 
  payment_date: '',
  created_at: '',
  updated_at: ''  
};
  