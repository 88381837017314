export const fourthStepAvtoDefaults = [
    {
        index: 1,
        name: 'main',
        table_name: 'Таблица 1. Матрица сравнений для расчетов приоритетов критериев.',
        type: 'criteria',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'А',
            key: 'criteria_a',
          },
          {
            name: 'Б',
            key: 'criteria_b',
          },
          {
            name: 'В',
            key: 'criteria_v',
          },
          {
            name: 'Г',
            key: 'criteria_g',
          },
        ],
        table_values: [
            {
              name: 'А',
              key: 'criteria_a',
              values: [1, 9, 3, 3]
            },
            {
              name: 'Б',
              key: 'criteria_b',
              values: [0.11, 1, 3, 3]
            },
            {
              name: 'В',
              key: 'criteria_v',
              values: [0.33, 0.33, 1, 3]
            },
            {
              name: 'Г',
              key: 'criteria_g',
              values: [0.33, 0.33, 0.33, 1]
            }
        ]
    },
    {
        index: 2,
        name: 'method_1',
        table_name: 'Таблица 2. Критерий А. Возможность отразить действительные намерения потенциального инвестора или продавца',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Сравнительный',
            key: 'comparative',
          },
          {
            name: 'Затратный',
            key: 'expensive',
          },
        ],
        table_values: [
            {
              name: 'Сравнительный',
              key: 'comparative',
              values: [1, 9]
            },
            {
              name: 'Затратный',
              key: 'expensive',
              values: [1, 1]
            },
        ]
    },
    {
        index: 3,
        name: 'method_2',
        table_name: 'Таблица 3. Критерий Б. Тип, качество, обширность данных, на основе которых проводится анализ',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Сравнительный',
            key: 'comparative',
          },
          {
            name: 'Затратный',
            key: 'expensive',
          },
        ],
        table_values: [
            {
              name: 'Сравнительный',
              key: 'comparative',
              values: [1, 9]
            },
            {
              name: 'Затратный',
              key: 'expensive',
              values: [1, 1]
            },
        ]
    },
    {
        index: 4,
        name: 'method_3',
        table_name: 'Таблица 4. Критерий В. Способность параметров используемых методов учитывать коньюктурные колебания',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Сравнительный',
            key: 'comparative',
          },
          {
            name: 'Затратный',
            key: 'expensive',
          },
        ],
        table_values: [
            {
              name: 'Сравнительный',
              key: 'comparative',
              values: [1, 9]
            },
            {
              name: 'Затратный',
              key: 'expensive',
              values: [1, 1]
            },
        ]
    },
    {
        index: 5,
        name: 'method_4',
        table_name: 'Таблица 5. Критерий Г.Соответствие подхода оценки рассчитываемому виду стоимости, цели оценки и предполагаемому использованию результатов оценки',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Сравнительный',
            key: 'comparative',
          },
          {
            name: 'Затратный',
            key: 'expensive',
          },
        ],
        table_values: [
            {
              name: 'Сравнительный',
              key: 'comparative',
              values: [1, 9]
            },
            {
              name: 'Затратный',
              key: 'expensive',
              values: [1, 1]
            },
        ]
    },
    {
        index: 5,
        name: 'total',
        table_name: 'Таблица 6. Итоговое значение весов каждого метода',
        type: 'total',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'А',
            key: 'criteria_a',
          },
          {
            name: 'Б',
            key: 'criteria_b',
          },
          {
            name: 'В',
            key: 'criteria_v',
          },
          {
            name: 'Г',
            key: 'criteria_g',
          },
        ],
        table_values: [
          {
            name: '',
            values: [0.58, 0.19, 0.15, 0.08]
          },
          {
            name: 'Сравнительный',
            key: 'comparative',
            values: [0.75, 0.75, 0.75, 0.75]
          },
          {
            name: 'Затратный',
            key: 'expensive',
            values: [0.25, 0.25, 0.25, 0.25]
          },
        ]
    },
];
  
export default fourthStepAvtoDefaults;