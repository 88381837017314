<template>
    <div class="home home-t-app" style="margin-bottom: 50px">
        <div class="
                dashboard-main-bg
                dashboard-main-bg__full
                dashboard-main-bg_image-filter
                dashboard-main-bg_animated
            "> 
        </div>
        HOME PAGE
       


    </div>
</template>

<script>

import _ from "lodash";
import { formatMoney } from "@/filters/index";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Home",
    components: {
     
    },
    data() {
        return {
        
        };
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
        }),
      
    },
    created: function () {
    },
    mounted() {
       
    },
    beforeRouteLeave(to, from, next) {
        document.body.style.overflow = "visible";
        document.querySelector('.app-main').style.margin = "1rem 12px"
        next()
    },
    methods: {

    },
};
</script>

<style lang="scss">
// DASHBOARD
.dashboard-tabs .el-tabs__header {
    display: none;
}

.el-divider__text {
    font-size: 18px !important;
}

.drawer-footer .el-button,
.drawer-footer .el-select .el-input .el-input__inner {
    border-radius: 0 !important;

}

// ==================================================================

.bg-redcha {
    background-color: #ff6ba6bf !important;
}

.task-scroll-khan {
    overflow: hidden;
    overflow-y: scroll;
    height: 345px;
}

.task-scroll-khan::-webkit-scrollbar {
    width: 5px;
}

.task-scroll-khan::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: inherit;
}

.task-scroll-khan::-webkit-scrollbar-thumb {
    background-color: #0c253d6b;
    outline: 1px solid #ffffff12;
}

.el__cardnight {
    background: #100e1d !important;
    border: 1px dashed #323248 !important;
}

.el__cardday {
    background: #fff !important;
    border: 1px solid #fff;
}

.text__night {
    color: #000;
}

.mode__2 input {
    background: #323248 !important;
    border: 1px solid #323248 !important;
    color: #fff;
}

.task-scroll-khan-product {
    overflow: hidden;
    overflow-y: scroll;
    height: 400px;
}

.task-scroll-khan-product::-webkit-scrollbar {
    width: 5px;
}

.task-scroll-khan-product::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: rgba(12, 37, 61, 0.95);
}

.task-scroll-khan-product::-webkit-scrollbar-thumb {
    background-color: #0c253d6b;
    outline: 1px solid #ffffff12;
}


.filter-filyal .el-input__inner {
    background-color: #0e2b4500 !important;
    border-color: #bfc4c9 !important;
    color: #606266 !important;
    border-radius: 8px;
}

.w50 {
    width: 55%;
    padding-right: 5px;
}

.w20 {
    width: 20%;
}

.w10 {
    width: 5%;
}

.style-view {
    color: #fff;
    position: absolute;
    right: 0px;
    z-index: 2;
    font-size: 20px;
}

.style-view:hover {
    color: #f8b01a;
}

.doc-style {
    position: relative;
}

.custom__justify {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1rem;
}

// .custom__button label span {
//     // background: #0e2b4500 !important;
//     border-color:#e5e5e561 !important;
// }
.custom__button label span {
    background: #0e2b4500 !important;
    border-color: #e5e5e561 !important;
    color: #fff !important;
}

.custom__button label input:checked+.el-radio-button__inner {
    background-color: #409EFF !important;
}

.circle__user {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #f8b01a;
    border: 2px solid #8f6408;
    color: #fff;
    border-radius: 50%;
}

.user__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user__footer {
    display: flex;
    margin-top: 2px;
    flex-direction: column;
}

.user__footer span {
    font-size: 13px !important;
}

.user__deal {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.user__deal span {
    font-size: 14px !important;
}

.user__name span {
    font-size: 14px !important;
}

// asasasass
.agents__table {
    width: 100%;
    color: #fff;
}

.agents__table thead tr th {
    text-align: center;
}

.agents__table tbody tr td {
    padding: 8px 5px;
}

.agents__box {
    display: flex;
    flex-direction: row;
}

.agents__half {
    width: 50%;
}

.agents__circle {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.agents__green {
    background: #CCFF99;
}

.agents__red {
    background: #FF9999;
}

.agents__blue {
    background: #66B2FF;
}

.agents__yellow {
    background: #FFFF99;
}

.agents__center {
    text-align: center;
}

.agents__view span {
    font-size: 16px;
    cursor: pointer;
}

.agents__flex {
    display: flex;
    flex-direction: column;
}

@media screen and (max-height: 750px) {
    .agents__flex {
        display: flex;
        flex-direction: row;
    }

    .agents__full {
        width: 50%;
    }


}

.tech__item {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    // border-right: 1px solid rgb(53, 55, 68);
}

.tech__flex {
    display: flex;
}

.tech__color {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
}

.tech__name {
    color: #fff;
}

.tech__li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}

.tech__title {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.tech__title span {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.tech__title h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.tech__box {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
}


.tech__center {
    display: flex;
    align-items: center;
}

.tech__box::-webkit-scrollbar {
    height: 8px;
}

.tech__box::-webkit-scrollbar-thumb {
    background-color: #459EF7
}

.tech__box::-webkit-scrollbar-track {
    background-color: rgba(12, 37, 61, 0.95);
}

.without__margin div {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.margin__header {
    margin: 0 12px
}

.select__formday .el-input__inner {
    border-color: #bfc4c9 !important;
    color: #606266 !important;
}

.select__formnight .el-input__inner {
    border-color: #bdc2c7 !important;
    color: #ffffff !important;
}

.home {
    position: relative;
}

.h__responssive {
    height: 100%;
}
</style>

<!-- new dashboard style -->
<style lang="scss" scoped>
.home-status {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px;

    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.create-or-select-input {
    width: 100%;
}

.drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 40px;
    background-color: #fdfdfd;
    border-bottom: 1px solid #dddddd8f;

    // drawer-header__title
    &__title {
        color: #181818;
        // font-size: 1.5rem;
        font-weight: 600;
    }

    // drawer-header__close
    &__close {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 20px;
        cursor: pointer;
        color: #181818;
        transition: color .1s ease-in-out;

        &:hover {
            color: #00b2ff;
        }
    }

    // drawer-header__count
    &__count,
    &__count span {
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
    }
}

.drawer-body {
    padding: 12px 24px;
}

.drawer-footer {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    align-items: center;
    box-shadow: 0 0 8px 5px #ccc;
    border-radius: 5px;
    overflow: hidden;

    // drawer-footer__save
    &__save {
        outline: none;
        height: 40px;
        font-size: 12px;
        font-weight: 600;
        padding: 10px 20px;
        color: #67c23a;
        background: #f0f9eb;
        border: 1px solid #c2e7b0;
        // border-radius: 4px 0 0 4px;
        transition: .1s;

        &:hover {
            background: #67c23a;
            border-color: #67c23a;
            color: #fff;
        }
    }

    // drawer-footer__count-box
    &__count-box {
        flex-shrink: 0;
        height: 40px;
        padding: 10px 20px;
        border: 1px solid #b3d8ff;
        // border-radius: 0 4px 4px 0;
        line-height: 1.2;

        span {
            font-size: 12px;
            font-weight: 600;

            .counter {
                font-size: 1rem;
            }
        }
    }

    .el-button--success {
        background: rgb(103, 194, 58) !important;
        border-color: rgb(103, 194, 58) !important;
    }
}

.dashboard {
    // max-height: calc(100vh - 60px);
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 70px;

    // scrool bar style
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #409eff;
        border: 2px solid #fff0;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: #66b1ff;
        border-color: #66b1ff;
    }

    .divider {
        margin: 0;
    }
}

.dashboard-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: all .25s ease-in-out;

    &.sticky {
        background-color: rgba($color: #fff, $alpha: .9);
        box-shadow: 7px 4px 33px #ccc;
        backdrop-filter: blur(5px);

    }

    &.fixed {
        position: fixed;
        left: 0;
        width: 100%;
        padding: 13px 30px 13px 94px;
    }

    // dashboard-navbar-list
    &-list {
        display: flex;
        align-items: center;
        gap: 12px;

        .el-button {
            margin: 0 !important;
        }
    }
}

.dashboard-list {
    margin: 0;
    padding: 0;
    list-style: none;

    // .dashboard-list__item
    &__item {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        // .dashboard-list__item-name
        &-name {
            position: relative;
            flex-grow: 1;
            height: 100%;
            border: 1px solid #ddd;
            padding: 0 0 0 12px;
            margin: 0;
            border-radius: 6px 0 0 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            transition: all .1s ease-in-out;
            color: #00b2ff;
            border-color: #b3d8ff;
            overflow: hidden;

            .name {
                font-size: 1rem;
                font-weight: 500;
            }

            .edit-input {
                outline: none;
                width: 100%;
                height: 100%;
                font-size: 1rem;
                font-weight: 500;
                padding: 0 12px;
                border: none;
                position: absolute;
                left: 0;
                top: 0;
            }

            &.disabled {
                color: #909399;
                background: #f4f4f5;
                border-color: #d3d4d6;
            }
        }

        // dashboard-list__item-label
        &-label {
            position: relative;
            outline: none;
            overflow: hidden;
            font-size: 1rem;
            line-height: 24px;
            height: 40px;
            padding: 8px 20px;
            color: #409eff;
            background-color: #ecf5ff;
            border: 1px solid #b3d8ff;
            margin: 0 !important;
            cursor: pointer;

            &:hover {
                background: #409eff;
                border-color: #409eff;
                color: #fff;
            }

            .fa-solid {
                width: 20px;
            }

            .checkbox-input {
                position: absolute;
                left: 0;
                top: 0;
                display: none;
            }
        }

        // dashboard-list__item-actions
        &-actions {
            display: inline-flex;
            align-items: center;

            .counter {
                font-size: 1rem;
                line-height: 24px;
                height: 40px;
                padding: 8px 20px;
                color: #409eff;
                border: 1px solid #b3d8ff;
                margin: 0 !important;
            }

            button {
                height: 40px;
                padding: 8px 20px;
                outline: none;
                border: none;
                border: 1px solid #b3d8ff;
                font-weight: 500;
                font-size: 14px;
                transition: .1s;
                color: #409eff;
                background: #ecf5ff;

                &:hover {
                    background: #409eff;
                    border-color: #409eff;
                    color: #fff;
                }

                &:last-child {
                    color: #f56c6c;
                    background: #fef0f0;
                    border-color: #fbc4c4;
                    border-radius: 0 6px 6px 0;

                    &:hover {
                        background: #f56c6c;
                        border-color: #f56c6c;
                        color: #fff;
                    }
                }

                &:disabled,
                &:disabled:hover {
                    color: #909399;
                    background: #f4f4f5;
                    border-color: #d3d4d6;
                    cursor: not-allowed;
                }
            }

            &.disabled .counter,
            &.disabled .dashboard-list__item-label {
                color: #909399 !important;
                background: #f4f4f5 !important;
                border-color: #d3d4d6 !important;
            }
        }
    }
}

.chart-list {
    // padding: 12px;
    display: grid;
    // grid-template-columns: repeat(8, 1fr);
    gap: 1rem;

    // .chart-list__item
    &__item {
        display: block;
        width: 100%;
        position: relative;
        margin: 0;
        padding: 12px;
        text-align: center;
        border: 1px solid #727b89;
        border-radius: 5px;
        background-color: #fff;
        color: #727b89;
        font-size: .8rem;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        transition: all .1s ease-in-out;
        outline: none;

        // .chart-list__item-status
        &-status {
            position: absolute;
            top: 1px;
            right: 0;
            width: 30px;
            height: 30px;
            padding: 4px;
            border-radius: 5px;
            // background-color: #f8fafc;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.5rem;
            }
        }

        input {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
        }

        &>i {
            display: block;
            margin-bottom: 4px;
            font-size: 3.5rem;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        &:hover,
        &.selected {
            color: #00b2ff !important;
            border-color: #00b2ff !important;
        }

        &.done {
            border-color: #64748b !important;
            color: #64748b !important;
        }

        &.self_selected {
            border-color: #22c55e !important;
            color: #22c55e !important;
        }

        &.not-current-element {
            border-color: #64748b !important;
            color: #64748b !important;
        }
    }
}

.heap-status {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #18181b;
    transition: all .1s ease-in-out;

    span {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 5px;
        background-color: #22c55e;
    }

    &:last-child span {
        background-color: #64748b;
    }
}

.bg-opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    background: rgba(15, 43, 86, 0.539);
}
</style>
