export const model = {
    id: null,
    slug: '', 
    parent_id: null,
    has_child: false,
    calculation_type: null, 
    document_templates: [], 
    created_at: '',
    updated_at: '',
  };
  