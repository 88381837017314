export const filter = {
    id : '',  
    name: '', 
    slug: '', 
    parent_id: null, 
    has_child: false,
    calculation_type: null, 
    created_at: '',
    updated_at: ''  
  };
  