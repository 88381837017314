export const fifthStepTotal = {
        index: 12,
        name: 'criteria_1',
        table_name: 'Наименование использованных подходов',
        type: 'last_total',
        total_last: 135518731,

        column_names: [
          {
            name: 'Затратный',
            key: 'expensive',
            children: [
                {
                    name: 'Стоимость объекта',
                    key: 'price',
                },
                {
                    name: 'Вес. коэфф.',
                    key: 'coefficient',
                },
                {
                    name: 'Взвешенное  значение',
                    key: 'weighted_value',
                },
            ]
          },
          {
            name: 'Сравнительный',
            key: 'comparative',
            children: [
                {
                    name: 'Стоимость объекта',
                    key: 'price',
                },
                {
                    name: 'Вес. коэфф.',
                    key: 'coefficient',
                },
                {
                    name: 'Взвешенное  значение',
                    key: 'weighted_value',
                },
            ]
          },
        ],
        table_values: [
            {
              name: 'Chevrolet Cobalt 2021 г.в., гос.№ 01 U 009 CA',
              key: 'expensive',
              values: [109505300, 0.25, 27376325, 144189875, 0.75, 108142406]
            },
        ]
};
  
export default fifthStepTotal;