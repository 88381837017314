// export default router
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import { getToken } from "@/utils/auth";

Vue.use(VueRouter);

var routes = [
    {
      path: "/",
      name: "Home",
      meta: {
        layout: "main",
        public: false
      },
      component: Home
    },
    {
        name: 'twostep.index',
        path: '/twostep',
        component: () =>
            import ('../views/twostep/index.vue'),
        hidden: true
    },
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      hidden: true
    },
];



/**
 * Load all routes from modulea folder
 */
const request = require.context("./modules", true, /.js/);

request.keys().map(module => {
  routes = routes.concat(request(module).default);
});

const router = new VueRouter({
  routes: routes, 
  mode: "history"
});



//  This is making loop when login that is why commented //\\ Error: Maximum call stack size exceeded

// router.beforeEach((to, from, next) => {
//     console.log(getToken())
//     let hasToken = !!getToken();
//     let isPublic = to.matched.some((record) => record.meta.public);
  
//     if (hasToken && isPublic) {
//       return next(from.fullPath);
//     }
  
//     if (!hasToken && !isPublic) {
//       return next({ name: 'login' });
//     }
  
//     next();
// });

export default router;
