import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    object_name : {
        show: true,
        title: i18n.t('message.object_name'),
        sortable: true,
        column: 'object_name'
    },

    client_id : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client_id'
    },

    category_id : {
        show: true,
        title: i18n.t('message.category'),
        sortable: true,
        column: 'category_id'
    },

    region_id : {
        show: true,
        title: i18n.t('message.region'),
        sortable: true,
        column: 'region_id'
    },

    city_id : {
        show: true,
        title: i18n.t('message.city'),
        sortable: true,
        column: 'city_id'
    },

    completed : {
        show: true,
        title: i18n.t('message.completed_z'),
        sortable: true,
        column: 'completed'
    },

    other_inputs : {
        show: true,
        title: i18n.t('message.others'),
        sortable: true,
        column: 'other_inputs'
    },

    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
