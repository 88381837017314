export const fourthStepLandDefaults = [
    {
        index: 1,
        name: 'main',
        type: 'criteria',
        total_first: 0,
        total_last: 0,
        table_name: 'Влияния на достоверность величины рыночной стоимости',
        column_names: [
          {
            name: 'Методология расчёта',
            key: 'calculation_methodology',
          },
          {
            name: 'Исходные данные',
            key: 'initial_data',
          },
        ],
        table_values: [
            {
              name: 'Методология расчёта',
              key: 'calculation_methodology',
              values: [1, 1]
            },
            {
              name: 'Исходные данные',
              key: 'initial_data',
              values: [1, 1]
            },
        ]
    },
    {
        index: 2,
        name: 'criteria_1',
        table_name: 'Влияние на качество методологии расчёта',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Учёт принципа наиболее эфективного использования',
            key: 'accounting_principle',
          },
          {
            name: 'Обоснованность предпосылок выбранного метода',
            key: 'validity_premises',
          },
          {
            name: 'Количество и значимость субъективных показателей и предложений',
            key: 'quantity_and_significance',
          },
        ],
        table_values: [
            {
              name: 'Учёт принципа наиболее эфективного использования',
              key: 'accounting_principle',
              values: [1, 1, 1]
            },
            {
              name: 'Обоснованность предпосылок выбранного метода',
              key: 'validity_premises',
              values: [1, 1, 1]
            },
            {
              name: 'Количество и значимость субъективных показателей и предложений',
              key: 'quantity_and_significance',
              values: [1, 1, 1]
            },
        ]
    },
    {
        index: 3,
        name: 'criteria_2',
        table_name: 'Влияние на качество исходной информации',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Полнота исходной информации',
            key: 'completeness',
          },
          {
            name: 'Достоверность исходной информации',
            key: 'credibility',
          },
          {
            name: 'Соответствие требованиям рыночной стоимости',
            key: 'compliance_market_requirements',
          },
        ],
        table_values: [
            {
              name: 'Полнота исходной информации',
              key: 'completeness',
              values: [1, 0, 1]
            },
            {
              name: 'Достоверность исходной информации',
              key: 'credibility',
              values: [0, 0, 0]
            },
            {
              name: 'Соответствие требованиям рыночной стоимости',
              key: 'compliance_market_requirements',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 4,
        name: 'criteria_3',
        table_name: 'Учёт принципа наиболее эфективного использования',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Затратный подход',
            key: 'expensive',
          },
          {
            name: 'Доходный подход',
            key: 'profitable',
          },
          {
            name: 'Сравнительный подход',
            key: 'comparative',
          },
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 0, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 5,
        name: 'criteria_4',
        table_name: 'Обосновонность предпосылок выбранного метода',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Затратный подход',
            key: 'expensive',
          },
          {
            name: 'Доходный подход',
            key: 'profitable',
          },
          {
            name: 'Сравнительный подход',
            key: 'comparative',
          },
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 0, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 6,
        name: 'criteria_5',
        table_name: 'Колличество и значимость субъективных показателей и предложений',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Затратный подход',
            key: 'expensive',
          },
          {
            name: 'Доходный подход',
            key: 'profitable',
          },
          {
            name: 'Сравнительный подход',
            key: 'comparative',
          },
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 0, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 7,
        name: 'criteria_6',
        table_name: 'Полнота исходной информации',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Затратный подход',
            key: 'expensive',
          },
          {
            name: 'Доходный подход',
            key: 'profitable',
          },
          {
            name: 'Сравнительный подход',
            key: 'comparative',
          },
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 0, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 8,
        name: 'criteria_7',
        table_name: 'Достоверность  исходной информации',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Затратный подход',
            key: 'expensive',
          },
          {
            name: 'Доходный подход',
            key: 'profitable',
          },
          {
            name: 'Сравнительный подход',
            key: 'comparative',
          },
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 0, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 9,
        name: 'criteria_8',
        table_name: 'Соответствие требованиям рыночной стоимости',
        type: 'method',
        total_first: 0,
        total_last: 0,
        column_names: [
          {
            name: 'Затратный подход',
            key: 'expensive',
          },
          {
            name: 'Доходный подход',
            key: 'profitable',
          },
          {
            name: 'Сравнительный подход',
            key: 'comparative',
          },
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 0, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 0, 9]
            },
        ]
    },
    {
        index: 10,
        name: 'criteria_9',
        table_name: 'Значимость критериев качества выбранной методологии оценки',
        type: 'weight_significance',
        total_first: 0,
        total_last: 0,

        column_names: [
          {
            name: 'Удельный вес критерий',
            key: 'criteries',
            children: [
              {
                name: 'Учёт принципа наиболее эфективного использования',
                key: 'accounting_principle',
                value: 0.333
              },
              {
                name: 'Обоснованность предпосылок выбранного метода',
                key: 'validity_premises',
                value: 0.333
              },
              {
                name: 'Количество и значимость субъективных показателей и предложений',
                key: 'quantity_and_significance',
                value: 0.333
              },
            ]
          }
          
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [0.1, 0.1, 0.1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [0.9, 0.9, 0.9]
            },
        ]
    },
    {
        index: 11,
        name: 'criteria_10',
        table_name: 'Значимость критериев качество исходной информации',
        type: 'weight_significance',
        total_first: 0,
        total_last: 0,

        column_names: [
          {
            name: 'Удельный вес критерий',
            key: 'criteries',
            children: [
              {
                name: 'Полнота исходной информации',
                key: 'completeness',
                value: 0.333
              },
              {
                name: 'Достоверность исходной информации',
                key: 'credibility',
                value: 0.333
              },
              {
                name: 'Соответствие требованиям рыночной стоимости',
                key: 'compliance_market_requirements',
                value: 0.333
              },
            ]
          }
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [0.1, 0.1, 0.1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [0.9, 0.9, 0.9]
            },
        ]
    },
    {
        index: 12,
        name: 'criteria_11',
        table_name: 'Определение удельных весов',
        type: 'weight_significance',
        total_first: 0,
        total_last: 0,

        column_names: [
          {
            name: 'Удельный вес критерий',
            key: 'criteries',
            children: [
              {
                name: 'Методология расчёта',
                key: 'calculation_methodology',
                value: 0.5
              },
              {
                name: 'Исходные данные',
                key: 'initial_data',
                value: 0.5
              },
            ]
          }
        ],
        table_values: [
            {
              name: 'Затратный подход',
              key: 'expensive',
              values: [1, 1]
            },
            {
              name: 'Доходный подход',
              key: 'profitable',
              values: [0, 0]
            },
            {
              name: 'Сравнительный подход',
              key: 'comparative',
              values: [9, 9]
            },
        ]
    },
    
];
  
export default fourthStepLandDefaults;